/* to fix style of phone number with country code component used at login page */
.react-tel-input .form-control {
  padding: 18.5px 14px 18.5px 58px !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.react-tel-input .form-control {
  border-color: #bdbdbd !important;
}
.capitalize {
  text-transform: capitalize;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.mt-10 {
  margin: 10px 0;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center-bt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.icon-size {
  font-size: 1.2rem;
}
.padding-20 {
  padding: 20px;
}
.border-top-grey {
  border-top: 1px solid #eeeeee;
}

.empty-image-css {
  max-height: calc(100vh - 193px);
}
